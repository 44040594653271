import { XCircleIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";

export const Alert2 = ({ message, setError }) => {
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setError('');
            }, 4000);
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    return (
        <div
            className="fixed bottom-5 right-5 z-50"
        >
            <div className="flex items-center w-full max-w-xs p-4 rounded-lg shadow text-white bg-red-700" role="alert">
                <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg bg-white text-red-700">
                    <XCircleIcon className="w-6 h-6" />
                    <span className="sr-only">Fire icon</span>
                </div>
                <div className="ml-3 text-base font-normal break-words">
                    {message}
                </div>
                <button type="button" className="ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 inline-flex h-8 w-8 text-white hover:text-white bg-red-700 hover:bg-red-600"
                    onClick={() => { setError("") }}>
                    <span className="sr-only">Close</span>
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
            </div>
        </div>
    )
}