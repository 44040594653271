import Swiper from 'swiper/bundle';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useLazyQuery } from '@apollo/client';
import { ALL_PRODUCTOS_CLIENTE_VITRINA, ALL_PRODUCTO_CLIENTE_VITRINA } from '../../api/carousel/queries';
import { Fragment, useEffect, useState } from 'react';
import { useUserContext } from '../context/userContext';
import { useNavigate } from 'react-router-dom';
import { HeartIcon, QuestionMarkCircleIcon, ShareIcon } from "@heroicons/react/24/solid";
import domtoimage from 'dom-to-image';

export const CAROUSEL_LOCAL = ({ setCarouselLocal }) => {
    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper('.swiper', {
        speed: 400,
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 7500,
            disableOnInteraction: false
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        }
    });

    const { ctxUser } = useUserContext();
    const navigate = useNavigate();
    const [productos, setProductos] = useState([]);
    const [all_productos, { data }] = useLazyQuery(ALL_PRODUCTO_CLIENTE_VITRINA, {
        fetchPolicy: 'network-only',
        onError: (error) => {
            console.log(error.graphQLErrors[0].message);
        }
    });
    const [all_productos_g, { data: data2 }] = useLazyQuery(ALL_PRODUCTOS_CLIENTE_VITRINA, {
        fetchPolicy: 'network-only',
        onError: (error) => {
            console.log(error.graphQLErrors[0].message);
        }
    });

    useEffect(() => {
        if (ctxUser) {
            all_productos({
                variables: {
                    fkCliente: parseFloat(ctxUser?.pk_cliente || 0)
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctxUser]);

    useEffect(() => {
        if (data && data?.allProductoClienteVitrina) {
            setProductos(data.allProductoClienteVitrina);
            if (data.allProductoClienteVitrina?.length === 0) {
                all_productos_g();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (data2 && data2?.allProductosClienteVitrina) {
            setProductos(data2.allProductosClienteVitrina);
        }
    }, [data2]);

    const handleCompartir = () => {
        try {
            const element = document.getElementById('contenido-compartir');
            domtoimage
                .toBlob(element)
                .then(async function (blob) {
                    const file = new File([blob], 'producto.png', { type: blob.type });
                    await navigator.share({
                        url: "https://www.recargafacil.com.ec",
                        title: "Producto",
                        text: "Producto",
                        files: [file]
                    });
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {
                productos.length > 0 &&
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-full my-6 mx-auto h-full"
                /*onClick={() => setCarouselLocal(false)}*/>
                            <div className="border-0 relative flex flex-col w-full h-full bg-black bg-opacity-75 outline-none focus:outline-none">
                                <div className="relative p-4 flex-auto">
                                    <div className="swiper bg-transparent w-full h-full">
                                        <div className="swiper-wrapper">
                                            {productos.map((i, index) => (
                                                <Fragment key={index}>
                                                    <div className="swiper-slide">
                                                        <div className="card2">
                                                            <div className="content">
                                                                <div className="back">
                                                                    <div className="back-content max-h-full overflow-y-auto overflow-x-auto">
                                                                        <div className='w-full flex justify-center items-center mt-2'>
                                                                            <button
                                                                                type='button'
                                                                                onClick={handleCompartir}
                                                                                className='border-4 border-blue-600 shadow-md shadow-blue-500 rounded-2xl hover:text-black hover:bg-transparent py-0.5 px-2 font-semibold bg-blue-600 text-white flex'>
                                                                                Compartir <ShareIcon className="h-5 w-5 ml-1" />
                                                                            </button>
                                                                        </div>

                                                                        <div
                                                                            id="contenido-compartir"
                                                                            onClick={() => setCarouselLocal(false)}
                                                                            className='w-full flex flex-wrap bg-white'>
                                                                            <div className='w-full sm:w-1/2 h-48 sm:h-[25rem] px-4 py-4'>
                                                                                <div className='w-full h-5/6 flex'>
                                                                                    <img alt='Imagen' src={`${process.env.REACT_APP_URL_RES}${i.producto_cliente?.imagen || i.imagen}`} className='w-full object-contain' />
                                                                                </div>
                                                                                <p className='w-full text-black font-bold text-center'>Marketplace Recargafacil</p>
                                                                                <p className='w-full text-black font-bold text-center'>{i.producto_cliente?.tienda?.ciudad?.name || i.tienda?.ciudad?.name} - {i.producto_cliente?.tienda?.ciudad?.division_territorial?.name || i.tienda?.ciudad?.division_territorial?.name}</p>
                                                                            </div>
                                                                            <div className='w-full sm:w-1/2 px-4 py-4'>
                                                                                <div className="description py-2">
                                                                                    <div className='w-full'>
                                                                                        {((i.producto_cliente?.pvp || parseFloat(i.producto_cliente?.pvp || 0) > 0) || (i.pvp || parseFloat(i.pvp || 0) > 0)) &&
                                                                                            <button className="button text-3xl lg:text-5xl">
                                                                                                <span className="line-through decoration-gray-400 text-gray-400">&nbsp;${parseFloat(i.producto_cliente?.pvp || i.pvp).toFixed(2)}&nbsp;</span>
                                                                                            </button>
                                                                                        }
                                                                                        <button className="text-3xl lg:text-5xl">
                                                                                            <span className="hover-text">&nbsp;${parseFloat(i.pvp || 0).toFixed(2)}&nbsp;</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="title mt-3 mb-5">
                                                                                        <p className="w-full title font-bold text-2xl sm:text-4xl text-black">
                                                                                            {i.producto_cliente?.nombre || i.nombre}
                                                                                        </p>
                                                                                    </div>
                                                                                    {i.producto_cliente?.descripcion &&
                                                                                        <div className="mt-3">
                                                                                            <p className="w-full title font-semibold text-lg sm:text-2xl mt-2 text-black">
                                                                                                {i.producto_cliente?.descripcion || i.descripcion}
                                                                                            </p>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='w-full flex justify-center items-center text-sm sm:text-base'>
                                                                            <button
                                                                                type='button'
                                                                                onClick={() => navigate(`/tienda`, { state: { tab: 2 } })}
                                                                                className='border-4 border-blue-600 shadow-md shadow-blue-500 rounded-2xl hover:text-black hover:bg-transparent py-0.5 px-2 font-semibold bg-blue-600 text-white mr-4 flex'>
                                                                                ¿Por qué veo esto? <QuestionMarkCircleIcon className='ml-1 h-5 w-5' />
                                                                            </button>

                                                                            <button
                                                                                type='button'
                                                                                onClick={() => navigate(`/tienda/${i.producto_cliente?.fk_tienda || 0}?${i.producto_cliente?.nombre}`)}
                                                                                className='border-4 border-purple-600 shadow-md shadow-purple-500 rounded-2xl hover:text-black hover:bg-transparent py-0.5 px-2 font-semibold bg-purple-600 text-white flex'>
                                                                                Me interesa <HeartIcon className='ml-1 h-5 w-5' />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))}
                                        </div>

                                        <div className="swiper-pagination"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            }
        </>
    )
}