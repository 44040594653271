import { gql } from "@apollo/client"

export const LOGIN = gql`
  mutation login($username: Float!, $password: String!, $ip: String){
  login(username: $username, password: $password, ip: $ip) {
    value
  }
}
`;

export const LOGIN_ADMIN = gql`
mutation login_admin($username: Int!, $password: String!){
  login_admin(username: $username, password: $password) {
    value
  }
}
`;

export const LOGIN_SIN_VERIFICACION = gql`
mutation login_sv($username: Float!, $password: String!, $codigo: String!, $ip: String){
  login_sv(username: $username, password: $password, codigo: $codigo, ip: $ip) {
    value
  }
}
`;

export const LOGIN_BLOQUEO = gql`
mutation login_bloqueo($username: Float!, $codigo: String!, $ip: String){
  login_bloqueo(username: $username, codigo: $codigo, ip: $ip) {
    value
  }
}
`;

export const LOGIN_ACREDITACIONES = gql`
  mutation login_acreditacion($username: Float!) {
    loginAcreditacion(username: $username) {
      value
    }
  }
`

export const LOGIN_FACTURAS = gql`
mutation login_factura($username: Float!, $password: String!){
  loginFacturacion(username: $username, password: $password){
    value
  }
}
`

export const CAMBIAR_PASSWORD = gql`
  mutation cambiar_password($forgotPasswordId: String!, $email: String!){
    forgotPassword(pk_cliente: $forgotPasswordId, email: $email)
  }
`

export const ADD_SESIONES_USUARIO = gql`
mutation add_sesiones_usuario($addSesionUsuarioId: Int!, $fecha: String!, $hora: String!, $ip: String!){
  addSesionUsuario(id: $addSesionUsuarioId, fecha: $fecha, hora: $hora, ip: $ip)
}
`;

export const CHANGE_PASSWORD_CLIENT = gql`
mutation change_password_cliente($pk_cliente: Int!, $new_password: String!, $confirmation_password: String!){
  changePasswordClient(pk_cliente: $pk_cliente, new_password: $new_password, confirmation_password: $confirmation_password)
}
`;

export const SOLICITA_REACTIVACION = gql`
mutation solicitaReactivacion($fkCliente: Float!, $telefono: String!){
  solicitaReactivacion(fkCliente: $fkCliente, telefono: $telefono)
}
`;

export const LOGOUT = gql`
mutation logout($token: String!, $fkCliente: Float!){
  logout(token: $token, fkCliente: $fkCliente)
}
`;