import { gql } from '@apollo/client';

export const SUSCRIBIR_USUARIO = gql`
mutation suscribirUsuario($fkCliente: Float!, $texto: String!){
    suscribirUsuario(fkCliente: $fkCliente, texto: $texto)
}
`;

export const NUEVO_MSG = gql`
mutation nuevoMsg($fkCliente: Float!, $titulo: String!, $texto: String!){
    nuevoMsg(fkCliente: $fkCliente, titulo: $titulo, texto: $texto)
}
`;