export const ComprobarNumeroCedula = (cedula, tipo) => {
    if (typeof (cedula) == 'string' && cedula.length === 10 && /^\d+$/.test(cedula)) {
        let digitos = cedula.split('').map(Number);
        let codigo_provincia = digitos[0] * 10 + digitos[1];
        const natural = [2, 1, 2, 1, 2, 1, 2, 1, 2];
        /*const publico = [3, 2, 7, 6, 5, 4, 3, 2];
        const privado = [4, 3, 2, 7, 6, 5, 4, 3, 2];*/

        if (codigo_provincia >= 1 && codigo_provincia <= 24) {
            let digito_verificador = digitos.pop();
            let digito_calculado = 0;

            if (tipo === "C" || digitos[2] < 6) {
                digito_calculado = digitos.reduce(function (valorPrevio, valorActual, indice) {
                    return valorPrevio - (valorActual * natural[indice]) % 9 - (valorActual === 9) * 9;
                }, 1000) % 10;
            } else if (digitos[2] === 6) {
                /*digito_verificador = digitos.pop();
                digito_calculado = 11 - (digitos.reduce(function (valorPrevio, valorActual, indice) {
                    return valorPrevio + (valorActual * publico[indice]);
                }, 0) % 11);*/
                return true;
            } else if (digitos[2] === 9) {
                /*digito_calculado = 11 - (digitos.reduce(function (valorPrevio, valorActual, indice) {
                    return valorPrevio + (valorActual * privado[indice]);
                }, 0) % 11);*/
                return true;
            } else {
                return false;
            }

            return (digito_calculado === digito_verificador);
        }
    }
    return false;
}

export function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
} 