import React, { Suspense, lazy, useState, useEffect } from "react";
import "./App.css";
//import TagManager from "react-gtm-module/dist/TagManager";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Loader from "./components/loader/loader";
import Sidebar from "./components/nav/sidebar_inicio";
import SidebarMain from "./components/nav/sidebar";
import SidebarSalir from "./components/nav/nav_acreditaciones";

const Home = lazy(() => import("./views/home/Home"));
const Recargas = lazy(() => import("./views/user/recargas/recargas_menu"));
const Registro = lazy(() => import("./views/registro/panel"));
const ProcesarCuenta = lazy(() => import("./views/registro/procesar_cuenta"));
const Login = lazy(() => import("./views/login/login"));
const LoginAdmin = lazy(() => import("./views/login/login_admin"))
const Contact = lazy(() => import("./views/contact/contact"));
const Comprobante = lazy(() => import("./views/comprobante/comprobante"));
const LoginAcreditaciones = lazy(() => import("./views/acreditaciones/login"));
const Acreditaciones = lazy(() => import("./views/acreditaciones/acreditaciones"));
const RespuestaA = lazy(() => import("./views/acreditaciones/respuesta"));
const RecuperarCuenta = lazy(() => import("./views/recuperar_password/recuperar_password"));
const CodigoPassword = lazy(() => import("./views/recuperar_password/codigo"));
const NuevoPassword = lazy(() => import("./views/recuperar_password/nuevo"));
const Pagos = lazy(() => import("./views/user/pagos/pagos"));
const Bancos = lazy(() => import("./views/user/pagos/bancos"));
const Juegos = lazy(() => import("./views/user/pagos/juegos"));
const Operadora = lazy(() => import("./views/user/index/index"));
const Facturacion = lazy(() => import("./views/comprobante/factura"));
const IndexTienda = lazy(() => import("./views/user/tienda"));
const IndexCarrito = lazy(() => import("./views/user/tienda/detalle"));

/*Recargas*/
const RecargasClaro = lazy(() => import("./views/user/recargas/claro/menu"));
const RecargasClaroTv = lazy(() => import("./views/user/recargas/claro/claro_tv"));
const RecargasMovistar = lazy(() => import("./views/user/recargas/movistar/recargas_movistar_menu"));
const RecargasCnt = lazy(() => import("./views/user/recargas/cnt/recargas_cnt_menu"));
const RecargasTuenti = lazy(() => import("./views/user/recargas/tuenti/recargas_tuenti_menu"));
const RecargasFreeFire = lazy(() => import("./views/user/recargas/free_fire/panel_free"));
const RecargasCompra = lazy(() => import("./views/user/recargas/otros/panelRecargas"));
const RecargasPanelOperadoras = lazy(() => import("./views/user/recargas/panelRecargas"));
const RecargafacilEconocell = lazy(() => import("./views/user/recargas/transfer/recargafacil"));
const PanelStreaming = lazy(() => import("./views/user/recargas/free_fire/panel_streaming"));
const PanelFavoritos = lazy(() => import("./views/user/favoritos"));

const Configurar = lazy(() => import("./views/user/configuracion/index"));
const Reporte = lazy(() => import("./views/user/reportes/reportes"));
const Comisiones = lazy(() => import("./views/user/comisiones/comisiones"));
const Mensajes = lazy(() => import("./views/user/mensajes/mensajes"));
const Factura = lazy(() => import("./views/user/facturas/facturas"));
const Respuesta = lazy(() => import("./components/offline/offline"));
const AcreditacionesUser = lazy(() => import("./views/user/acreditaciones/acreditaciones"));
const Puntos = lazy(() => import("./views/user/puntos/puntos"));
const IndexInfoReferidos = lazy(() => import("./views/user/info_referidos/index"));

/*Servicios*/
const PagoAgua = lazy(() => import("./views/user/pagos/servicios_basicos/agua"));
const PagoLuz = lazy(() => import("./views/user/pagos/servicios_basicos/luz"));
const PagoLuzTotal = lazy(() => import("./views/user/pagos/servicios_basicos/luzTotal"));
const PanelGenerico = lazy(() => import("./views/user/pagos/otros/generico"));
//const Supa = lazy(() => import("./views/user/pagos/insti_estado/supa"));
const PlanCnt = lazy(() => import("./views/user/pagos/planes_moviles/cnt"));
const ConsultaPagos = lazy(() => import("./views/user/pagos/otros/panelOtros"));
const ConsultaCuentas = lazy(() => import("./views/user/pagos/cuentas"));
const IndexBancos = lazy(() => import("./views/user/pagos/bancos/index"));
const PanelBancos = lazy(() => import("./views/user/pagos/bancos/panelBancos"));
const PanelBancosI = lazy(() => import("./views/user/index/bancos"));
const PanelMagis = lazy(() => import("./views/user/recargas/free_fire/panel_magis"));
const PanelIndexLoteria = lazy(() => import("./views/user/recargas/free_fire/loteria"));
const PanelIndexBoletos = lazy(() => import("./views/user/recargas/free_fire/loteria/boletos"));


/* Rifas */
const IndexRifas = lazy(() => import("./views/user/rifas/index"));
const DatosComprador = lazy(() => import("./views/user/rifas/comprador"));
const BoletosRifas = lazy(() => import("./views/user/rifas/boletos"));
const RespuestaRifas = lazy(() => import("./views/user/rifas/respuesta"));
const ReciboRifas = lazy(() => import("./views/user/rifas/recibo"));

/*Administrador*/
const IndexAdmin = lazy(() => import("./components/nav/sidebar_admin"));
const Dashboard = lazy(() => import("./views/admin/dashboard/index"));
const IndexRecaudacion = lazy(() => import("./views/admin/recaudaciones/index"));
const ListarRecaudaciones = lazy(() => import("./views/admin/recaudaciones/listar"));
const EditarRecaudacion = lazy(() => import("./views/admin/recaudaciones/editar"));
const NuevaRecaudacion = lazy(() => import("./views/admin/recaudaciones/nuevo"));
const NuevoServicio = lazy(() => import("./views/admin/servicios/nuevo"));
const ListarServicios = lazy(() => import("./views/admin/servicios/listar"));
const EditarServicio = lazy(() => import("./views/admin/servicios/editar"));
const ListarBancos = lazy(() => import("./views/admin/bancos/listar"));
const EditarBanco = lazy(() => import("./views/admin/bancos/editar"));
const NuevoBanco = lazy(() => import("./views/admin/bancos/nuevo"));
const IndexOperadora = lazy(() => import("./views/admin/operadoras/index"));
const ListarOperadora = lazy(() => import("./views/admin/operadoras/listar"));
const NuevaOperadora = lazy(() => import("./views/admin/operadoras/nuevo"));
const EditarOperadora = lazy(() => import("./views/admin/operadoras/editar"));
const ListarRecargas = lazy(() => import("./views/admin/recargas/listar"));
const NuevaRecarga = lazy(() => import("./views/admin/recargas/nuevo"));
const EditarRecarga = lazy(() => import("./views/admin/recargas/editar"));
const ListarPaquetes = lazy(() => import("./views/admin/paquetes/listar"));
const NuevoPaquete = lazy(() => import("./views/admin/paquetes/nuevo"));
const EditarPaquete = lazy(() => import("./views/admin/paquetes/editar"));
const ListarCliente = lazy(() => import("./views/admin/clientes/index"));
const EditarCliente = lazy(() => import("./views/admin/clientes/editar"));
const DetalleCliente = lazy(() => import("./views/admin/clientes/detalles"));
const ListarAuspiciante = lazy(() => import("./views/admin/auspiciantes/index"));
const NuevoAuspiciante = lazy(() => import("./views/admin/auspiciantes/nuevo"));
const EditarAuspiciante = lazy(() => import("./views/admin/auspiciantes/editar"));
const DetalleAuspiciante = lazy(() => import("./views/admin/auspiciantes/detalles"));
const IndexCarousel = lazy(() => import("./views/admin/carousel/index"));
const NuevoCarousel = lazy(() => import("./views/admin/carousel/nuevo"));
const EditarCarousel = lazy(() => import("./views/admin/carousel/editar"));
const IndexPublicidad = lazy(() => import("./views/admin/publicidad/index"));
const NuevaPublicidadInicio = lazy(() => import("./views/admin/publicidad/inicio/nuevo"));
const EditarPublicidadInicio = lazy(() => import("./views/admin/publicidad/inicio/editar"));
const NuevaPublicidadAfiche = lazy(() => import("./views/admin/publicidad/afiche/nuevo"));
const EditarPublicidadAfiche = lazy(() => import("./views/admin/publicidad/afiche/editar"));
const NuevaPublicidadMarquesina = lazy(() => import("./views/admin/publicidad/marquesina/nuevo"));
const EditarPublicidadMarquesina = lazy(() => import("./views/admin/publicidad/marquesina/editar"));
const EditarPuntos = lazy(() => import("./views/admin/puntos/index"));
const ListarSolAcre = lazy(() => import("./views/admin/acreditaciones/listar"));
const IndexSorteo = lazy(() => import("./views/admin/rifas/index"));
const NuevoSorteo = lazy(() => import("./views/admin/rifas/nuevo"));
const EditarSorteo = lazy(() => import("./views/admin/rifas/editar"));
const RepoteSorteo = lazy(() => import("./views/admin/rifas/reportes/reportes"));
const ListarTrx = lazy(() => import("./views/admin/trx/index"));
const ListarDepositos = lazy(() => import("./views/admin/depositos/index"));
const RespuestaRecargas = lazy(() => import("./views/user/recargas/respuesta"));
const RespuestaPagos = lazy(() => import("./views/user/pagos/respuesta"));
const ReciboPagos = lazy(() => import("./views/user/pagos/recibo"));
const ReciboPdf = lazy(() => import("./views/user/pagos/recibo_pdf"));
const P404 = lazy(() => import("./views/404"));
const IndexProveedores = lazy(() => import("./views/admin/proveedores/index"));
const DetalleProveedores = lazy(() => import("./views/admin/proveedores/detalle"));
const EditarProveedores = lazy(() => import("./views/admin/proveedores/editar"));
const Publicidad = lazy(() => import("./views/user/index/publicidad"));
const Notificaciones = lazy(() => import("./views/admin/notificaciones/index"));
const CodigoPromocional = lazy(() => import("./views/user/codigo_promocional/index"));
const RespuestaCodigoPromocional = lazy(() => import("./views/user/codigo_promocional/respuesta"));
const RespuestaMultipagos = lazy(() => import("./components/nav/respuesta"));
const ReciboMultipagos = lazy(() => import("./components/nav/recibo"));
const PanelVitrina = lazy(() => import("./views/user/vitrina"));
const PanelProductos = lazy(() => import("./views/user/vitrina/productos"));
const PanelProductosTienda = lazy(() => import("./views/user/tienda/productos"));
const IndexCarrito2 = lazy(() => import("./views/user/tienda/carrito2"));
const ReciboWeb = lazy(() => import("./views/user/tienda/recibo"));
const IndexLoteriaRecargas = lazy(() => import("./views/user/recargas/loteria"));
const IndexTerminosSorteo = lazy(() => import("./views/user/sorteo"));

/*const TagManagerArgs = {
  gtmId: "GTM-PS63MJS"
}

TagManager.initialize(TagManagerArgs)

TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    path: "/"
  }
})*/

function App() {
  const [token, setToken] = useState(window.localStorage.getItem('information-user') || sessionStorage.getItem('information-user'))
  const [token2, setToken2] = useState(sessionStorage.getItem('acreditacion-user'))
  const [token3, setToken3] = useState(window.localStorage.getItem('information-admin'))

  const imprimir = () => {
    setToken(window.localStorage.getItem('information-user') || sessionStorage.getItem('information-user'));
    setToken3(window.localStorage.getItem('information-admin'));
  }

  useEffect(() => {
    window.addEventListener('storage', imprimir);

    return () => {
      window.removeEventListener('storage', imprimir);
    }
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        {/* Econocell */}
        <div className="flex">
          <Routes>
            <Route path="/" element={<Sidebar />}>
              <Route index element={<Home token={token} />} />
              <Route path="home" element={<Home token={token} />} />
              <Route path="register">
                <Route index element={<Registro />} />
                <Route path="procesar" element={<ProcesarCuenta />} />
              </Route>
              <Route path="login" element={token === null ? <Login setToken={setToken} /> : <Navigate to="/operadora" />} />
              <Route path="login/admin" element={<LoginAdmin setToken={setToken3} />} />
              <Route path="contact" element={<Contact />} />
              <Route path="comprobante" element={<Comprobante setToken={setToken2} />} />
              <Route path="recuperar_cuenta">
                <Route index element={<RecuperarCuenta />} />
                <Route path="codigo" element={<CodigoPassword />} />
                <Route path="nuevo" element={<NuevoPassword />} />
              </Route>
              <Route
                path="login_acreditacion"
                element={<LoginAcreditaciones setToken={setToken2} />}
              />
            </Route>

            <Route path="/" element={<SidebarSalir setToken={setToken2} />}>
              <Route path="acreditaciones">
                <Route index element={token2 ? <Acreditaciones token={token2} /> : <Navigate to="/login_acreditacion" />} />
                <Route path="respuesta" element={<RespuestaA />} />
              </Route>
              <Route path="facturas" element={token2 ? <Facturacion token={token2} /> : <Navigate to="/comprobante" />} />
            </Route>

            {/* *************** RecargaFácil ********************** */}
            {/*Administrador*/}
            <Route exact path="/admin" element={token3 ? <IndexAdmin setToken={setToken3} /> : <Navigate to="/login" />}>
              <Route index element={<Dashboard />} />
              <Route path="dashboard">
                <Route index element={<Dashboard />} />
              </Route>
              <Route path="notificaciones">
                <Route index element={<Notificaciones />} />
              </Route>
              <Route path="recaudaciones">
                <Route index element={<IndexRecaudacion />} />
                <Route path="listar" element={<ListarRecaudaciones />} />
                <Route path="editar/:id" element={<EditarRecaudacion />} />
                <Route path="nuevo" element={<NuevaRecaudacion />} />
              </Route>
              <Route path="servicios">
                <Route index element={<ListarServicios />} />
                <Route path="listar" element={<ListarServicios />} />
                <Route path="nuevo" element={<NuevoServicio />} />
                <Route path="editar/:id" element={<EditarServicio />} />
              </Route>
              <Route path="bancos">
                <Route index element={<ListarBancos />} />
                <Route path="listar" element={<ListarBancos />} />
                <Route path="editar/:id" element={<EditarBanco />} />
                <Route path="nuevo" element={<NuevoBanco />} />
              </Route>
              <Route path="operadoras">
                <Route index element={<IndexOperadora />} />
                <Route path="listar" element={<ListarOperadora />} />
                <Route path="editar/:id" element={<EditarOperadora />} />
                <Route path="nuevo" element={<NuevaOperadora />} />
              </Route>
              <Route path="recargas">
                <Route index element={<ListarRecargas />} />
                <Route path="listar" element={<ListarRecargas />} />
                <Route path="editar/:id" element={<EditarRecarga />} />
                <Route path="nuevo" element={<NuevaRecarga />} />
              </Route>
              <Route path="paquetes">
                <Route index element={<ListarPaquetes />} />
                <Route path="listar" element={<ListarPaquetes />} />
                <Route path="editar/:id" element={<EditarPaquete />} />
                <Route path="nuevo" element={<NuevoPaquete />} />
              </Route>
              <Route path="clientes">
                <Route index element={<ListarCliente />} />
                <Route path="listar" element={<ListarCliente />} />
                <Route path="editar/:id" element={<EditarCliente />} />
                <Route path="detalle/:id" element={<DetalleCliente />} />
              </Route>
              <Route path="auspiciantes">
                <Route index element={<ListarAuspiciante />} />
                <Route path="listar" element={<ListarAuspiciante />} />
                <Route path="nuevo" element={<NuevoAuspiciante />} />
                <Route path="editar/:id" element={<EditarAuspiciante />} />
                <Route path="detalle/:id" element={<DetalleAuspiciante />} />
              </Route>
              <Route path="carousel">
                <Route index element={<IndexCarousel />} />
                <Route path="nuevo" element={<NuevoCarousel />} />
                <Route path="editar/:id" element={<EditarCarousel />} />
              </Route>
              <Route path="puntos" element={<EditarPuntos />} />
              <Route path="acreditaciones" element={<ListarSolAcre />} />
              <Route path="rifas">
                <Route index element={<IndexSorteo />} />
                <Route path="nuevo" element={<NuevoSorteo />} />
                <Route path="editar/:id" element={<EditarSorteo />} />
                <Route path="reporte/:id" element={<RepoteSorteo />} />
              </Route>
              <Route path="trx">
                <Route index element={<ListarTrx />} />
              </Route>
              <Route path="depositos">
                <Route index element={<ListarDepositos />} />
              </Route>
              <Route path="proveedores">
                <Route index element={<IndexProveedores />} />
                <Route path="detalle/:id" element={<DetalleProveedores />} />
                <Route path="editar/:id" element={<EditarProveedores />} />
              </Route>
              <Route path="publicidad">
                <Route index element={<IndexPublicidad />} />
                <Route path="inicio/nuevo" element={<NuevaPublicidadInicio />} />
                <Route path="inicio/editar/:id" element={<EditarPublicidadInicio />} />
                <Route path="afiche/nuevo" element={<NuevaPublicidadAfiche />} />
                <Route path="afiche/editar/:id" element={<EditarPublicidadAfiche />} />
                <Route path="marquesina/nuevo" element={<NuevaPublicidadMarquesina />} />
                <Route path="marquesina/editar/:id" element={<EditarPublicidadMarquesina />} />
              </Route>
            </Route>

            {/*Página general*/}
            <Route path="/" element={<SidebarMain setToken={setToken} token={token} />}>
              <Route index element={<Operadora token={token} />} />
              <Route path="operadora">
                <Route index element={<Operadora token={token} />} />
              </Route>

              <Route path="recargafacil_econocell" element={<RecargafacilEconocell token={token} setToken={setToken} />} />

              <Route path="recargas">
                <Route index element={<Recargas />} />
                <Route path="loteria" element={<IndexLoteriaRecargas token={token} setToken={setToken} />} />
                <Route path="tuenti">
                  <Route index element={<RecargasTuenti />} />
                  <Route
                    path=":id/:tipo/:valor"
                    element={<RecargasPanelOperadoras setToken={setToken} token={token} />}
                  />
                </Route>
                {/*Cnt*/}
                <Route path="cnt">
                  <Route index element={<RecargasCnt />} />
                  <Route
                    path=":id/:tipo/:valor"
                    element={<RecargasPanelOperadoras setToken={setToken} token={token} />}
                  />
                </Route>
                {/*Claro*/}
                <Route path="claro">
                  <Route index element={<RecargasClaro />} />
                  <Route
                    path=":id/:tipo/:valor"
                    element={<RecargasPanelOperadoras setToken={setToken} token={token} />}
                  />
                </Route>
                {/*Movistar*/}
                <Route path="movistar">
                  <Route index element={<RecargasMovistar />} />
                  <Route
                    path=":id/:tipo/:valor"
                    element={<RecargasPanelOperadoras setToken={setToken} token={token} />}
                  />
                </Route>
                {/*ClaroTv*/}
                <Route path="claro_tv">
                  <Route index element={<RecargasClaroTv />} />
                  <Route
                    path=":id/:tipo/:valor"
                    element={<RecargasPanelOperadoras setToken={setToken} token={token} />}
                  />
                </Route>
                {/*Operadoras extras*/}
                <Route path="free_fire" element={<RecargasFreeFire setToken={setToken} token={token} />}></Route>
                <Route
                  path=":name/:id"
                  element={<RecargasCompra setToken={setToken} token={token} />}
                />
                <Route path="respuesta" element={<RespuestaRecargas token={token} />} />
              </Route>
              <Route path="panel_bancos" element={<PanelBancosI token={token} />} />
              {/*Bancos*/}
              <Route path="bancos">
                <Route index element={<Bancos />} />
                <Route path="banco_pichincha/:id" element={<IndexBancos token={token} />} />
                <Route path="banco_guayaquil/:id" element={<IndexBancos token={token} />} />
                <Route path="banco_pacifico/:id" element={<IndexBancos token={token} />} />
                <Route path="banco_pichincha_-_visa_credito/:id" element={<IndexBancos token={token} />} />
                <Route path="banco_pichincha_-_mastercard_credito/:id" element={<IndexBancos token={token} />} />
                <Route path="banco_pichincha_-_diners_credito/:id" element={<IndexBancos token={token} />} />
                <Route path="banco_guayaquil_-_american_express/:id" element={<IndexBancos token={token} />} />
                <Route path=":name/:id" element={<PanelGenerico setToken={setToken} token={token} />} />
                <Route path="consulta/:id" element={<PanelBancos token={token} setToken={setToken} />} />
              </Route>

              {/*Juegos*/}
              <Route path="juegos">
                <Route index element={<Juegos />} />
                {/*<Route path="free_fire/1" element={<RecargasFreeFire setToken={setToken} token={token} />}></Route>*/}
                <Route path="hbo/:id" element={<PanelStreaming setToken={setToken} token={token} />} />
                <Route path="netflix/:id" element={<PanelStreaming setToken={setToken} token={token} />} />
                <Route path="disney/:id" element={<PanelStreaming setToken={setToken} token={token} />} />
                <Route path="spotify/:id" element={<PanelStreaming setToken={setToken} token={token} />} />
                <Route path="star/:id" element={<PanelStreaming setToken={setToken} token={token} />} />
                <Route path="paramount/:id" element={<PanelStreaming setToken={setToken} token={token} />} />
                <Route path="amazon/:id" element={<PanelStreaming setToken={setToken} token={token} />} />
                <Route path="youtube/:id" element={<PanelStreaming setToken={setToken} token={token} />} />
                <Route path="crunchyroll/:id" element={<PanelStreaming setToken={setToken} token={token} />} />
                <Route path="magistv/:id" element={<PanelMagis setToken={setToken} token={token} />} />
                <Route path="mont-stream/:id" element={<PanelMagis setToken={setToken} token={token} />} />
                <Route path="pronosticos_betcris/:id" element={<PanelGenerico token={token} />} />
                <Route path="pronosticos_ecuabet/:id" element={<PanelGenerico token={token} />} />
                <Route path="pronosticos_bet593/:id" element={<PanelGenerico token={token} />} />
                <Route path="pronosticos_trebalia/:id" element={<PanelGenerico token={token} />} />
                <Route path="loteria/:id" element={<PanelIndexLoteria token={token} />} />
                <Route path="pozo/:id" element={<PanelIndexLoteria token={token} />} />
                <Route path="lotto/:id" element={<PanelIndexLoteria token={token} />} />
                <Route path=":name/:id/boletos" element={<PanelIndexBoletos token={token} />} />
                <Route path=":name/:id" element={<RecargasFreeFire setToken={setToken} token={token} />} />
              </Route>

              {/*Pagos*/}
              <Route path="pagos">
                <Route index element={<Pagos />} />
                <Route path="agua/2" element={<PagoAgua setToken={setToken} token={token} />} />
                <Route path="luz/1" element={<PagoLuz setToken={setToken} token={token} />} />
                <Route path="cnt/3" element={<PlanCnt setToken={setToken} token={token} />} />
                <Route path="luz_total/6" element={<PagoLuzTotal setToken={setToken} token={token} />} />
                {/*<Route path="supa/110" element={<Supa setToken={setToken} token={token} />} />*/}
                <Route path=":name/:id" element={<PanelGenerico token={token} />} />
                <Route path="consultar/:id" element={<ConsultaPagos setToken={setToken} token={token} />} />
                <Route path="respuesta" element={<RespuestaPagos token={token} />} />
                <Route path="consultas_cuentas" element={<ConsultaCuentas />} />
              </Route>

              {/*Otras opciones*/}
              <Route path="configurar" element={<Configurar token={token} />} />
              <Route path="mensajes" element={<Mensajes token={token} />} />
              <Route path="facturacion" element={<Factura token={token} />} />
              <Route path="acreditacion_user">
                <Route index element={<AcreditacionesUser token={token} setToken={setToken} />} />
                <Route path="respuesta" element={<RespuestaA />} />
              </Route>
              <Route path="reporte" element={<Reporte token={token} />} />
              <Route path="auspiciados" element={<Comisiones token={token} />} />
              <Route path="puntos" element={<Puntos token={token} />} />
              <Route path="rifas">
                <Route index element={<IndexRifas />} />
                <Route path="comprador/:id" element={<DatosComprador />} />
                <Route path="boletos/:id" element={<BoletosRifas token={token} setToken={setToken} />} />
                <Route path="respuesta" element={<RespuestaRifas token={token} />} />
              </Route>
              <Route path="codigo_promocional">
                <Route index element={<CodigoPromocional token={token} />} />
                <Route path="respuesta" element={<RespuestaCodigoPromocional />} />
              </Route>
              <Route path="info_referidos" element={<IndexInfoReferidos token={token} />} />
              <Route path="/favoritos" element={<PanelFavoritos token={token} />} />
              <Route path="/vitrina" element={<PanelVitrina />} />
              <Route path="/productos/:id" element={<PanelProductos />} />
              <Route path="multipagos/respuesta" element={<RespuestaMultipagos />} />
              <Route path="tienda" element={<IndexTienda />} />
              <Route path="tienda/:id" element={<PanelProductosTienda />} />
              <Route path="carrito" element={<IndexCarrito />} />
              <Route path="carrito/:id" element={<IndexCarrito2 />} />
              <Route path="terminos_sorteo" element={<IndexTerminosSorteo />} />
            </Route>

            <Route path="respuesta" element={<Respuesta />} />
            <Route path="recibo/:id" element={<ReciboPagos />} />
            <Route path="recibo_web/:id" element={<ReciboWeb />} />
            <Route path="recibo_multipagos/:id" element={<ReciboMultipagos />} />
            <Route path="recibo_pdf/:id" element={<ReciboPdf />} />
            <Route path="rifas/recibo/:id" element={<ReciboRifas />} />
            <Route path="publicidad/:nombre" element={<Publicidad />} />
            <Route path="*" element={<P404 />} />
          </Routes>
        </div>
      </Suspense>
    </BrowserRouter>
  )
}

export default App;