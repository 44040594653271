import React, { useEffect, useState } from "react";
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate, Outlet, NavLink } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { useApolloClient, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { COUNT_MENSAJES, ME } from "../../api/header/queries";
import Loader from '../../components/loader/loader';
import {
  ChevronLeftIcon,
  Cog8ToothIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
  EnvelopeIcon,
  QuestionMarkCircleIcon,
  TableCellsIcon,
  ArrowTrendingUpIcon,
  UserPlusIcon,
  BanknotesIcon,
  ArrowDownTrayIcon,
  XCircleIcon,
  TicketIcon,
  UsersIcon,
  CurrencyDollarIcon,
  RocketLaunchIcon,
  BuildingStorefrontIcon,
  WalletIcon
} from "@heroicons/react/20/solid";
import Tooltip from "rc-tooltip";
import Publicidad from "../carousel/publicidad";
import { Marquesina } from "../marquee/marquesina";
import { useUserContext } from "../context/userContext";
import { Alert2 } from "../alerts/alert2";
import { DocumentTextIcon, EyeIcon, EyeSlashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { PanelMultipago } from "./multipago";
import { useMultipagoContext } from "../context/multipagoContext";
import { ADD_MULTIPAGO } from "../../api/pagos/mutations";
import { ADD_VISITA_LICORSARIATO, CHANGE_MOSTRAR_SALDO } from "../../api/header/mutation";
import { GET_AUTENTICACION } from "../../api/huellas_clientes/queries";
import { Validando } from "../alerts/validando";
import { client } from '@passwordless-id/webauthn';
import { SALIR_MULTISERVICIOS } from "../../api/huellas_clientes/mutations";
import { urlBase64ToUint8Array } from "../verificacion/cedula";
import { SUSCRIBIR_USUARIO } from "../../api/suscripciones/mutation";
import { CAROUSEL_LOCAL } from "./carousel_local";
import { Spinner } from "../loader/spinner";
import { LOGOUT } from "../../api/login/mutations";

const Sidebar = ({ setToken, token }) => {
  const navigation = [
    { name: "Inicio", href: "/operadora", icon: <HomeIcon className="h-6 w-6" /> },
    {
      name: "Mis puntos",
      href: "/puntos",
      icon: <ArrowTrendingUpIcon className="h-6 w-6" />,
    },
    {
      name: "Reportes",
      href: "/reporte",
      icon: <TableCellsIcon className="h-6 w-6" />,
    },
    {
      name: "Mensajes",
      href: "/mensajes",
      iconAlternative: <EnvelopeIcon className="h-6 w-6 text-green-700" />,
      icon: <EnvelopeIcon className="h-6 w-6" />,
    },
    {
      name: "Configurar",
      href: "/configurar",
      icon: <Cog8ToothIcon className="h-6 w-6" />,
    },
    {
      name: "Acreditar saldo",
      href: "/acreditacion_user",
      icon: <BanknotesIcon className="h-6 w-6" />,
    },
    {
      name: "Auspiciados",
      href: "/auspiciados",
      icon: <CurrencyDollarIcon className="h-6 w-6" />,
    },
    {
      name: "Mis Clientes",
      href: "/favoritos",
      icon: <UsersIcon className="h-6 w-6" />,
    },
    {
      name: "Tienda",
      href: "/tienda",
      icon: <BuildingStorefrontIcon className="h-6 w-6" />,
    },
    {
      name: "Vitrina",
      href: "/vitrina",
      icon: <WalletIcon className="h-6 w-6" />,
    },
    {
      name: "Facturación",
      href: "/facturacion",
      icon: <DocumentDuplicateIcon className="h-6 w-6" />,
    },
    {
      name: "Canjear código",
      href: "/codigo_promocional",
      icon: <TicketIcon className="h-6 w-6" />
    }
  ];
  const cliente = useApolloClient();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [cargando, setCargando] = useState(false);
  const [cargando2, setCargando2] = useState(false);
  const [cargando3, setCargando3] = useState(false);
  const [carouselLocal, setCarouselLocal] = useState(false);
  const [mostarSaldo, setMostrarSaldo] = useState(true);
  const [openContacto, setOpenContacto] = useState(false);
  const [error, setError] = useState('');
  const [isReadyForInstall, setIsReadyForInstall] = useState(false);
  const { ctxUser, setCtxUser } = useUserContext();
  const { ctxMP, setCtxMP } = useMultipagoContext();
  const [minutos, setMinutos] = useState(5);
  const { data, loading } = useQuery(ME, {
    pollInterval: 110000,
    onError: (error) => {
      setError(error.graphQLErrors[0].message)
    }
  });
  const { data: data2, loading: loading2 } = useQuery(COUNT_MENSAJES, {
    variables: { countMensajesId: (parseInt(data?.me?.pk_cliente) || 0) },
    pollInterval: 180000,
    onError: (error) => {
      setError(error.graphQLErrors[0].message)
    }
  });
  const [addMultipago, { data: data3, loading: loading3 }] = useMutation(ADD_MULTIPAGO, {
    refetchQueries: [
      { query: ME }
    ],
    onError: (error) => {
      setCargando2(false);
      setCargando(false);
      setError(error.graphQLErrors[0].message);
    }
  });
  const [addVisita, { data: data4, loading: loading4 }] = useMutation(ADD_VISITA_LICORSARIATO, {
    onError: (error) => {
      setError(error.graphQLErrors[0].message);
    }
  });
  const [get_autenticacion, { data: data5, loading: loading5 }] = useLazyQuery(GET_AUTENTICACION, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      setCargando3(false);
      setError(error.graphQLErrors[0].message);
    }
  });
  const [salir_multiservicios, { data: data6, loading: loading6 }] = useMutation(SALIR_MULTISERVICIOS, {
    onError: (error) => {
      setCargando3(false);
      setError(error.graphQLErrors[0].message);
    }
  });
  // eslint-disable-next-line no-unused-vars
  const [suscribirUsuario, { loading: loading7 }] = useMutation(SUSCRIBIR_USUARIO, {
    onError: (error) => {
      setError(error.graphQLErrors[0].message)
    }
  });
  const [editMostrarSaldo, { loading: loading8 }] = useMutation(CHANGE_MOSTRAR_SALDO, {
    onError: (error) => {
      setError(error.graphQLErrors[0].message);
    }
  });
  const [mutationLogout, { data: data9, loading: loading9 }] = useMutation(LOGOUT, {
    onError: (error) => setError(error.graphQLErrors[0].message)
  });

  const logout = () => {
    if (token) {
      mutationLogout({
        variables: {
          token: token,
          fkCliente: parseFloat(ctxUser?.pk_cliente)
        }
      });
    } else {
      window.localStorage.removeItem("information-user");
      window.sessionStorage.clear();
      window.dispatchEvent(new Event("storage"));
      setToken(null);
      setCtxUser({});
      cliente.clearStore();
      navigate("/login", { replace: true });
      navigator.clearAppBadge();
    }
  }

  const logoutMultiservicio = () => {
    setCargando3(true);
    get_autenticacion({
      variables: {
        fkCliente: parseInt(data?.me?.pk_cliente)
      }
    });
  }

  const handleOnIdle = () => {
    logout();
  }

  const { start } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    startOnMount: false,
    startManually: true,
    debounce: 500,
    crossTab: true,
    syncTimers: 200,
    name: 'idle-timer'
  });

  const handleIdle = () => {
    navigate("/operadora");
  }

  const { start: star2 } = useIdleTimer({
    timeout: 1000 * 60 * 2,
    onIdle: handleIdle,
    startOnMount: false,
    startManually: true,
    debounce: 500,
    crossTab: true,
    syncTimers: 200,
    name: 'idle-multipagos'
  });

  const handleIdleCarouselLocal = () => {
    setCarouselLocal(true);
  }

  const { start: start3, pause: pause3 } = useIdleTimer({
    timeout: 1000 * 60 * minutos,
    onIdle: handleIdleCarouselLocal,
    startOnMount: false,
    startManually: true,
    debounce: 500,
    crossTab: true,
    syncTimers: 200,
    name: 'idle-carousel-local'
  });

  async function downloadApp() {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      return;
    }

    promptEvent.prompt();
    const result = await promptEvent.userChoice;
    console.log("userChoice", result);
    window.deferredPrompt = null;
    setIsReadyForInstall(false);
  }

  const handleLicorsariato = () => {
    addVisita({
      variables: {
        fkCliente: parseFloat(ctxUser?.pk_cliente || 0)
      }
    });
  }

  const autenticar = (datosAuth) => {
    client.authenticate({
      "challenge": datosAuth.challenge,
      "hints": [],
      "authenticatorType": "auto",
      "userVerification": "required",
      "timeout": 60000,
      "allowCredentials": []
    }).then((authentication) => (
      salir_multiservicios({
        variables: {
          fkCliente: parseInt(data?.me?.pk_cliente),
          payload: JSON.stringify(authentication)
        }
      })
    )).catch((error) => {
      console.log(error);
      setError("Este dispositivo no está registrado");
      setCargando3(false);
    });
  }

  const verificarSuscripcionPush = async () => {
    const register = await navigator.serviceWorker.getRegistration();
    if (register) {
      const newSubscription = await register.pushManager.getSubscription();
      if (newSubscription) {
        const oldSubscription = JSON.parse(localStorage.getItem('subscription'));
        //if (newSubscription.endpoint !== oldSubscription?.endpoint) {
        localStorage.setItem('subscription', JSON.stringify(newSubscription));
        fetch(`${process.env.REACT_APP_URL_API}`, {
          method: 'POST',
          headers: { "Content-Type": "application/json", },
          body: JSON.stringify({
            variables: {
              fkCliente: parseFloat(data?.me?.pk_cliente || 0),
              oldEndpoint: oldSubscription ? oldSubscription.endpoint : null,
              newEndpoint: JSON.stringify(newSubscription)
            },
            query: "mutation($fkCliente: Float!, $oldEndpoint: String, $newEndpoint: String!){\nupdateSuscribirUsuario(fkCliente: $fkCliente, old_endpoint: $oldEndpoint, new_endpoint: $newEndpoint)\n}"
          })
        })
        //}
      } else {
        const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
        const register = await navigator.serviceWorker.getRegistration();
        const subscribe = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY),
        });
        suscribirUsuario({
          variables: {
            fkCliente: parseFloat(ctxUser?.pk_cliente),
            texto: JSON.stringify(subscribe)
          }
        });
        localStorage.setItem('subscription', JSON.stringify(subscribe));
      }
    }
  }

  const changeMostrarSaldo = () => {
    setMostrarSaldo(!mostarSaldo);
    if (ctxUser && ctxUser?.pk_cliente) {
      editMostrarSaldo({
        variables: {
          fkCliente: parseFloat(ctxUser?.pk_cliente),
          estado: !mostarSaldo ? 'S' : 'N'
        }
      });
    }
  }

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      window.deferredPrompt = event;
      setIsReadyForInstall(true);
    });

    window.addEventListener('appinstalled', () => {
      setIsReadyForInstall(false);
      window.deferredPrompt = null;
    });

    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) || window.innerWidth <= 780) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, []);

  useEffect(() => {
    if (ctxUser?.pk_cliente && 'serviceWorker' in navigator) {
      verificarSuscripcionPush();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator, ctxUser]);

  useEffect(() => {
    if (data && data?.me) {
      setCtxUser(data.me);
      if (data.me?.tiempo_inactividad === 'S') {
        start();
      }
      if (data.me?.multiservicios === 'S') {
        star2();
      }
      if (data.me?.activar_vitrina === 'S') {
        start3();
        setMinutos(parseFloat(data.me?.minutos_espera_vitrina));
      } else {
        pause3();
      }
      if (data.me?.multipagos === 'S') {
        document.body.style.backgroundColor = "#fdba74";
      } else {
        document.body.style.backgroundColor = "#facc15";
      }

      setMostrarSaldo(data.me?.mostrar_saldo === 'S');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data2 && data2?.countMensajes) {
      Bagde();

      async function Bagde() {
        if (!navigator.setAppBadge) return;
        // Try to set the app badge.
        try {
          await navigator.setAppBadge(data2.countMensajes);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [data2]);

  useEffect(() => {
    if (data3 && data3?.addMultipago) {
      setCargando(false);
      window.localStorage.removeItem("multipago");
      setCtxMP(false);
      navigate('/multipagos/respuesta', {
        replace: true,
        state: { dat: data3.addMultipago }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data3]);

  useEffect(() => {
    if (data4 && data4?.addVisitaLicorsariato) {
      window.open("https://www.licorsariato.com", '_blank', 'noopener, noreferrer');
    }
  }, [data4]);

  useEffect(() => {
    if (data5 && data5?.getAutenticacion) {
      autenticar(JSON.parse(data5.getAutenticacion));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data5]);

  useEffect(() => {
    if (data6 && data6?.salirMultiservicios) {
      setCargando3(false);
      /*cliente.refetchQueries({
        include: [ME],
      });*/
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data6]);

  useEffect(() => {
    if (data9 && data9?.logout) {
      window.localStorage.removeItem("information-user");
      window.sessionStorage.clear();
      window.dispatchEvent(new Event("storage"));
      setToken(null);
      setCtxUser({});
      cliente.clearStore();
      navigate("/login", { replace: true });
      navigator.clearAppBadge();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data9]);

  useEffect(() => {
    if (mostarSaldo) {
      const timer = setTimeout(() => changeMostrarSaldo(), 5000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mostarSaldo]);

  if (loading || loading2 || cargando || loading3 || loading9) return <Loader />
  return (
    <>
      <Publicidad />
      <div className={(open ? "w-40 sm:w-48 " : "w-14 sm:w-16 ") + "bg-amber-500 h-screen pt-2 px-2 relative duration-200 shadow-lg overflow-y-auto overflow-x-hidden"}>
        <div className="w-full flex items-center justify-center">
          <ChevronLeftIcon
            className={`cursor-pointer w-6 h-6 border-amber-600 bg-amber-600
           			border-2 rounded-full  ${!open && "rotate-180"}`}
            onClick={() => setOpen(!open)}
          />
        </div>

        <div className="w-full mt-2">
          <button onClick={() => navigate("/operadora", { replace: true })}>
            <div className="flex gap-x-4 items-center">
              <img
                src="/img/Nav/logo.png"
                alt="logo" width="" height=""
                className={`duration-500 h-9 w-9 ${open && "rotate-[360deg]"}`}
              />
              <h1
                className={`text-white origin-left font-medium text-sm sm:text-lg duration-200 
                                        ${!open && "scale-0"}`}
              >
                RecargaFácil
              </h1>
            </div>
          </button>
        </div>

        <div className="pt-4 sm:pt-1">
          {data?.me?.multiservicios === 'S' && token ?
            <Tooltip placement="right" overlay="Salir Modo Multiservicios">
              <button
                onClick={logoutMultiservicio}
                aria-label="Salir Modo Multiservicios"
                className="flex rounded-md my-1 p-1 hover:bg-blue-500 text-white font-semibold 
                            text-xs sm:text-sm items-center gap-x-2 w-full"
              >
                <XCircleIcon className="h-7 w-7 text-red-700" />
                <span className={`${!open && "hidden"} origin-left duration-150`}>
                  Salir Modo Multiservicios
                </span>
              </button>
            </Tooltip>
            :
            <>
              {isReadyForInstall &&
                <Tooltip placement="right" overlay="Descargar">
                  <button
                    onClick={downloadApp}
                    className="flex rounded-md my-0.5 p-1 hover:bg-green-400 text-black font-semibold 
                            text-xs sm:text-sm items-center gap-x-4 bg-green-500 w-full"
                    aria-label="Descargar"
                  >
                    <ArrowDownTrayIcon className="h-6 w-6 text-green-900" />
                    <span className={`${!open && "hidden"} origin-left duration-150`}>
                      Descargar
                    </span>
                  </button>
                </Tooltip>
              }

              <Tooltip placement="right" overlay={'Licorsariato'} key={'Licorsariato'}>
                <div
                  className="flex rounded-md my-1 p-1.5 bg-gray-800 text-white font-semibold text-xs sm:text-sm items-center gap-x-4 cursor-pointer"
                  onClick={handleLicorsariato}>
                  <div className="h-7">
                    <img src="/img/Nav/licorsariato.jpg" alt="Licorsariato"
                      className="w-full h-full object-center object-contain" />
                  </div>
                  <span className={`${!open && "hidden"} origin-left duration-150`}>
                    Licorsariato
                  </span>
                </div>
              </Tooltip>

              <Tooltip placement="right" overlay={'Referidos'} key={'Referidos'}>
                <NavLink
                  to='/info_referidos'
                  className="flex rounded-md my-1 p-1.5 hover:bg-blue-500 text-indigo-700 font-semibold 
                                text-xs sm:text-sm items-center gap-x-4"
                  activeclassname="active"
                  aria-label={'Referidos'}
                >
                  <UserPlusIcon className="w-6 h-6" />

                  <span className={`${!open && "hidden"} origin-left duration-150`}>
                    Referidos
                  </span>
                </NavLink>
              </Tooltip>

              {navigation.map((item) => (
                item.name === 'Auspiciados' ?
                  ((data?.me?.rol || 'cliente') === 'auspiciante' || (data?.me?.rol || 'cliente') === 'administrador') &&
                  <Tooltip placement="right" overlay={item.name} key={item.name}>
                    <NavLink
                      to={item.href}
                      className="flex rounded-md my-1 p-1.5 hover:bg-blue-500 text-white font-semibold 
                                text-xs sm:text-sm items-center gap-x-4"
                      activeclassname="active"
                      aria-label={item.name}
                    >
                      {item.icon}

                      <span className={`${!open && "hidden"} origin-left duration-150`}>
                        {item.name}
                      </span>
                    </NavLink>
                  </Tooltip>
                  :
                  <Tooltip placement="right" overlay={item.name} key={item.name}>
                    <NavLink
                      to={item.href}
                      className="flex rounded-md my-1 p-1.5 hover:bg-blue-500 text-white font-semibold 
                            text-xs sm:text-sm items-center gap-x-4"
                      activeclassname="active"
                      aria-label={item.name}
                    >
                      {item.name === "Mensajes" ?
                        (data2?.countMensajes || 0) === 0 ?
                          item.icon
                          :
                          <div className="relative">
                            <div className="absolute">
                              <span className="relative flex h-3.5 w-3.5">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75"></span>
                                <span className="relative inline-flex rounded-full h-3.5 w-3.5 bg-red-700"></span>
                              </span>
                            </div>
                            {item.iconAlternative}
                          </div>
                        :
                        item.icon
                      }

                      <span className={`${!open && "hidden"} origin-left duration-150`}>
                        {item.name}
                      </span>
                      {(data2?.countMensajes || 0) !== 0 && item.name === "Mensajes" && (
                        <span className={`${!open && "hidden"} origin-left duration-150`}>
                          <div className="w-full flex h-full content-center items-center justify-center">
                            <div className="p-3 w-5 h-5 text-black shadow-lg rounded-full bg-white 
                                            inline-flex items-center justify-center">
                              {`${data2?.countMensajes}`}
                            </div>
                          </div>
                        </span>
                      )}
                    </NavLink>
                  </Tooltip>
              ))}

              <Tooltip placement="right" overlay="Ayuda">
                <a
                  href="https://www.youtube.com/@econocell2761"
                  target="_blank"
                  className="flex rounded-md my-1 p-1.5 hover:bg-blue-500 text-white font-semibold 
                            text-xs sm:text-sm items-center gap-x-4"
                  rel="noreferrer"
                  aria-label="Ayuda"
                >
                  <QuestionMarkCircleIcon className="h-6 w-6 text-blue-800" />
                  <span className={`${!open && "hidden"} origin-left duration-150`}>
                    Ayuda
                  </span>
                </a>
              </Tooltip>

              {token ?
                <Tooltip placement="right" overlay="Salir">
                  <button
                    onClick={logout}
                    aria-label="Salir"
                    className="flex rounded-md my-1 p-1 hover:bg-blue-500 text-white font-semibold 
                            text-xs sm:text-sm items-center gap-x-4 w-full"
                  >
                    <XCircleIcon className="h-7 w-7 text-red-700" />
                    <span className={`${!open && "hidden"} origin-left duration-150`}>
                      Salir
                    </span>
                  </button>
                </Tooltip>
                :
                <Tooltip placement="right" overlay="Iniciar sesión">
                  <button
                    onClick={logout}
                    aria-label="Salir"
                    className="flex rounded-md my-1 p-2 hover:bg-blue-500 text-white font-semibold 
                            text-xs sm:text-sm items-center gap-x-4 w-full"
                  >
                    <ArrowRightOnRectangleIcon className="h-6 w-6 text-blue-800" />
                    <span className={`${!open && "hidden"} origin-left duration-150`}>
                      Iniciar sesión
                    </span>
                  </button>
                </Tooltip>
              }
            </>
          }
        </div>

      </div>

      <div className="h-screen flex-1 p-0 overflow-x-auto">
        <header className="shadow bg-amber-400">
          <div className="py-1 px-4 sm:px-6 lg:px-8 text-center rounded-sm">
            <div className="flex flex-wrap">

              {data?.me?.multiservicios === 'S' && token ?
                <div className="w-full">
                  <div className="flex w-full h-full justify-center items-center text-2xl sm:text-3xl py-3 font-bold text-blue-900">
                    AUTOSERVICIO
                  </div>
                </div>
                :
                <>
                  <div className="w-full">
                    <div className="w-full">
                      <span className="text-sm sm:text-lg font-bold">Usuario:  </span>
                      <span className="text-sm sm:text-lg font-bold text-blue-900">
                        {mostarSaldo ? (data?.me?.nombres || 'Invitado') : '** **'}
                      </span>
                    </div>
                    <div className="w-full flex flex-wrap">
                      <div className="w-1/2 sm:w-1/3 px-3">
                        <span className="text-sm sm:text-lg font-bold">Código:  </span>
                        <span className="text-sm sm:text-lg font-bold text-blue-900">
                          {mostarSaldo ? (data?.me?.pk_cliente || 0) : '**'}
                        </span>
                      </div>
                      <div className="w-1/2 sm:w-1/3 px-3">
                        <span className="text-sm sm:text-lg font-bold">Mis puntos:  </span>
                        <span className="text-sm sm:text-lg font-bold text-blue-900">
                          {mostarSaldo ? (data?.me?.puntos || 0) : '**'}
                        </span>
                      </div>
                      <div className="w-full sm:w-1/3 px-3 flex mt-1 justify-center items-center">
                        <div className="text-sm sm:text-lg font-bold flex items-center justify-center">
                          Mi saldo:
                          <span className="text-blue-900 flex items-center justify-center ml-2">
                            {mostarSaldo ? (data?.me?.saldo || 0) : '**.**'}
                            {loading8 ?
                              <Spinner tamanio={6} />
                              : (mostarSaldo ? <EyeSlashIcon className="h-5 w-5 ml-2 cursor-pointer" onClick={changeMostrarSaldo} /> : <EyeIcon className="h-5 w-5 ml-2 cursor-pointer" onClick={changeMostrarSaldo} />)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<div className="w-full sm:w-1/4 flex flex-wrap bg-yellow-300 rounded mb-1">
                    <div className="w-full font-bold">
                      Contáctanos aquí
                    </div>
                    <div className="w-1/3 flex justify-center items-center">
                      <a
                        href="https://api.whatsapp.com/send/?phone=593998475702&text=Hola,%20necesito%20ayuda&type=phone_number&app_absent=0"
                        target="_blank"
                        rel="noreferrer">
                        <img
                          src="/img/Index/soporte_ws.png"
                          className="h-14"
                          alt="whatsapp" />
                      </a>
                    </div>
                    <div className="w-1/3 flex justify-center items-center">
                      <a
                        href="https://api.whatsapp.com/send/?phone=593981500330&text=Hola,%20necesito%20mas%20informacion&type=phone_number&app_absent=0"
                        target="_blank"
                        rel="noreferrer">
                        <img
                          src="/img/Index/afiliacion_ws.png"
                          className="h-14"
                          alt="whatsapp" />
                      </a>
                    </div>
                    <div className="w-1/3 flex justify-center items-center">
                      <a
                        href="tel:+59352928772"
                        target="_blank"
                        rel="noreferrer">
                        <img
                          src="/img/Index/soporte_tlf.png"
                          className="h-14"
                          alt="whatsapp" />
                      </a>
                    </div>
                  </div>*/}
                </>
              }
            </div>
          </div>
        </header >
        <div className="">
          <Marquesina />
          <Outlet context={[cargando, setCargando]} />

          {carouselLocal &&
            <CAROUSEL_LOCAL
              setCarouselLocal={setCarouselLocal}
            />
          }

          {
            token &&
            <div className="z-10 fixed right-4 bottom-4 group">
              <div className={(openContacto ? "block " : "hidden ") + "flex flex-col items-center mb-4 space-y-2"}>
                <button
                  type="button"
                  className="flex justify-center p-2 object-center items-center w-[4.25rem] h-[4.25rem] bg-white rounded-full border border-gray-200 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 focus:outline-none">
                  <a
                    href="https://api.whatsapp.com/send/?phone=593998475702&text=Hola,%20necesito%20ayuda&type=phone_number&app_absent=0"
                    target="_blank"
                    rel="noreferrer">
                    <img
                      src="/img/Index/soporte_ws.png"
                      className="object-contain"
                      alt="whatsapp" />
                  </a>
                </button>

                <button
                  type="button"
                  className="flex justify-center p-2 object-center items-center w-[4.25rem] h-[4.25rem] bg-white rounded-full border border-gray-200 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 focus:outline-none">
                  <a
                    href="https://api.whatsapp.com/send/?phone=593981500330&text=Hola,%20necesito%20mas%20informacion&type=phone_number&app_absent=0"
                    target="_blank"
                    rel="noreferrer">
                    <img
                      src="/img/Index/afiliacion_ws.png"
                      className="object-contain"
                      alt="whatsapp" />
                  </a>
                </button>

                <button
                  type="button"
                  className="flex justify-center p-2 object-center items-center w-[4.25rem] h-[4.25rem] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 focus:outline-none">
                  <a
                    href="tel:+59352928772"
                    target="_blank"
                    rel="noreferrer">
                    <img
                      src="/img/Index/soporte_tlf.png"
                      className="object-contain"
                      alt="whatsapp" />
                  </a>
                </button>
              </div>
              {(data?.me?.multipagos || 'N') === 'N' &&
                <button
                  type="button"
                  onClick={() => setOpenContacto(!openContacto)}
                  className="flex items-center justify-center text-white font-semibold bg-blue-700 rounded-full w-[4.25rem] h-[4.25rem] hover:bg-blue-800 focus:outline-none">
                  {openContacto ? <XMarkIcon className="h-7 w-7" /> : "Contacto"}
                </button>
              }
            </div>
          }

          {
            !token &&
            <>
              <div className="card lg:bottom-5 lg:w-96 lg:right-[28rem] flex justify-center items-center">
                <button
                  type="button"
                  onClick={logout}
                  className="w-full">
                  INICIAR SESIÓN/ REGISTRARSE
                </button>
              </div>

              <button type="button" className="block fixed bottom-4 lg:bottom-5 h-16 w-16 right-4 lg:right-5 bg-green-600 rounded-full p-2 z-20">
                <a
                  href="https://api.whatsapp.com/send/?phone=593981500330&text=Hola,%20necesito%20mas%20informacion&type=phone_number&app_absent=0"
                  target="_blank"
                  rel="noreferrer">
                  <img
                    src="/img/Index/whatsapp.png"
                    alt="Whatsapp"
                    className="w-full object-center object-contain p-0.5"
                  />
                </a>
              </button>
            </>
          }

          {
            data?.me?.multipagos === 'S' &&
            <button
              type="button"
              onClick={() => { setCtxMP(true) }}
              className={(ctxMP ? "hidden " : "fixed ") + "bottom-4 lg:bottom-5 h-12 w-12 right-4 lg:right-5 bg-teal-600 rounded-full p-2 z-20 flex justify-center items-center"}>
              <DocumentTextIcon className="h-8 w-8 text-white" />
            </button>
          }

          {
            ctxMP &&
            <PanelMultipago
              token={token}
              setCtxMP={setCtxMP}
              ctxMP={ctxMP}
              setCargando={setCargando}
              addMultipago={addMultipago}
              cargando2={cargando2}
              setCargando2={setCargando2}
            />
          }

        </div>
        <Footer />
      </div >

      {
        error &&
        <Alert2 message={error} setError={setError} />
      }

      {
        loading4 &&
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="flex items-center w-full max-w-xs p-4 rounded-lg shadow text-white bg-fuchsia-700" role="alert">
              <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg bg-white text-fuchsia-700">
                <RocketLaunchIcon className="w-6 h-6" />
              </div>
              <div className="ml-3 text-base font-normal break-words">
                Redirigiendo....
              </div>
            </div>
          </div >
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      }

      {
        (loading5 || loading6 || cargando3) &&
        <Validando />
      }
    </>
  )
}

export default Sidebar;