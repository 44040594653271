import { gql } from '@apollo/client'

export const TRANSFERENCIA_SALDO = gql`
mutation transferencia_saldo($fkCliente: Int!, $fkRecaudacion: Int!, $cuentaDestino: Int!, $cantidad: Float!){
  transferenciaSaldo(fk_cliente: $fkCliente, fk_recaudacion: $fkRecaudacion, cuenta_destino: $cuentaDestino, cantidad: $cantidad) {
    id
    cuenta_destino
    cantidad
    total_venta
    fecha
    hora
    respuesta
    estado
    puntos
    recaudacion {
      name
      empresa
      proveedors {
        comision
      }
    }
    cliente {
      saldo_anterior
      saldo
    }
  }
}
`;

export const CODIGO_TRANSFERENCIA = gql`
mutation codigo_transferencia (
  $fkCliente: Int!
  $fkRecaudacion: Int!
  $cuentaDestino: Int!
  $cantidad: Float!
  $codigo: String!
) {
  codigoTransferencia(
    fk_cliente: $fkCliente
    fk_recaudacion: $fkRecaudacion
    cuenta_destino: $cuentaDestino
    cantidad: $cantidad
    codigo: $codigo
  ) {
    id
    cuenta_destino
    cantidad
    total_venta
    fecha
    hora
    respuesta
    estado
    puntos
    recaudacion {
      name
      empresa
      proveedors {
        comision
      }
    }
    cliente {
      saldo_anterior
      saldo
    }
  }
}
`;

export const ADD_PAGO = gql`
mutation addPagoCliente($fkCliente: Int!, $fkRecaudacion: Int!, $cuentaDestino: String!, $cantidad: Float!, $origen: String!, $trx: String!, $secuenciaConsulta: String!, $opciones: [OpcionesRecaudacion2], $token: String!, $ip: String!, $otp: String){
  addPagoCliente(fk_cliente: $fkCliente, fk_recaudacion: $fkRecaudacion, cuenta_destino: $cuentaDestino, cantidad: $cantidad, origen: $origen, trx: $trx, secuencia_consulta: $secuenciaConsulta, opciones: $opciones, token: $token, ip: $ip, otp: $otp) {
    id
    cuenta_destino
    cantidad
    total_venta
    fecha
    hora
    respuesta
    estado
    puntos
    cliente {
      saldo
      saldo_anterior
    }
    recaudacion {
      name
      empresa
      proveedors {
        comision
      }
    }
  }
}
`;

export const ADD_RECARGA = gql`
mutation addRecargaCliente($fkCliente: Int!, $fkRecaudacion: Int!, $cuentaDestino: String!, $cantidad: Float!, $origen: String!, $ip: String!, $token: String!, $otp: String){
  addRecargaCliente(fk_cliente: $fkCliente, fk_recaudacion: $fkRecaudacion, cuenta_destino: $cuentaDestino, cantidad: $cantidad, origen: $origen, ip: $ip, token: $token, otp: $otp) {
    id
    cuenta_destino
    cantidad
    total_venta
    fecha
    hora
    respuesta
    estado
    puntos
    recaudacion {
      name
      empresa
      proveedors {
        comision
      }
    }
    cliente {
      saldo_anterior
      saldo
    }
    codigo_promocional {
      id
      valor
      valor_vendedor
    }
  }
}
`;

export const ADD_PAGO_PUNTOS = gql`
mutation add_pago_puntos($fkCliente: Int!, $fkRecaudacion: Int!, $cuentaDestino: String!, $cantidad: Float!, $origen: String!, $token: String!, $ip: String!){
  addPagoPuntos(fk_cliente: $fkCliente, fk_recaudacion: $fkRecaudacion, cuenta_destino: $cuentaDestino, cantidad: $cantidad, origen: $origen, token: $token, ip: $ip) {
    id
    cuenta_destino
    cantidad
    total_venta
    fecha
    hora
    respuesta
    estado
    recaudacion {
      name
      empresa
    }
    cliente {
      saldo_anterior
      saldo
    }
  }
}
`;

export const ADD_RECARGA_STREAMING = gql`
  mutation add_recarga_streaming($fkCliente: Int!, $fkRecaudacion: Int!, $cedula: String!, $cantidad: Float!, $origen: String!, $telefono: String!, $email: String!, $ip: String!, $token: String!){
    addRecargaStreaming(fk_cliente: $fkCliente, fk_recaudacion: $fkRecaudacion, cedula: $cedula, cantidad: $cantidad, origen: $origen, telefono: $telefono, email: $email, ip: $ip, token: $token){
      id
      cuenta_destino
      cantidad
      total_venta
      fecha
      hora
      respuesta
      estado
      puntos
      recaudacion {
        name
        empresa
        proveedors {
          comision
        }
      }
      cliente {
        saldo_anterior
        saldo
      }
    }
  }
`;

export const ADD_RECARGA_MAGIS = gql`
  mutation addRecargaMagis($fkCliente: Int!, $fkRecaudacion: Int!, $cedula: String!, $cantidad: Float!, $origen: String!, $telefono: String!, $nombre: String!, $token: String!, $ip: String!){
    addRecargaMagis(fk_cliente: $fkCliente, fk_recaudacion: $fkRecaudacion, cedula: $cedula, cantidad: $cantidad, origen: $origen, telefono: $telefono, nombre: $nombre, token: $token, ip: $ip){
      id
      cuenta_destino
      cantidad
      total_venta
      fecha
      hora
      respuesta
      estado
      puntos
      recaudacion {
        name
        empresa
        proveedors {
          comision
        }
      }
      cliente {
        saldo_anterior
        saldo
      }
    }
  }
`;

export const ADD_CLIENTE_WS = gql`
  mutation add_cliente_ws($numero_movil: String, $fk_pago: Int!){
    addClientesWs(numero_movil: $numero_movil, fk_pago: $fk_pago)
  }
`;

export const ADD_DEPOSITO_BANCO = gql`
mutation addDepositoBanco($fkCliente: Int!, $fkRecaudacion: Int!, $cuenta: String!, $nombre: String!, $cantidad: Float!, $telefono: String, $cedula: String){
  addDepositoBanco(fk_cliente: $fkCliente, fk_recaudacion: $fkRecaudacion, cuenta: $cuenta, nombre: $nombre, cantidad: $cantidad, telefono: $telefono, cedula: $cedula) {
    id
    cuenta_destino
    cantidad
    total_venta
    fecha
    hora
    respuesta
    estado
    puntos
    recaudacion {
      name
      empresa
      proveedors {
        comision
      }
    }
    cliente {
      saldo_anterior
      saldo
    }
  }
}
`;

export const ADD_MULTIPAGO = gql`
mutation addMultipago($fkCliente: Int!, $detalle: [detalleMultipago]!){
  addMultipago(fk_cliente: $fkCliente, detalle: $detalle)
}
`;

export const ACTUALIZAR_MC = gql`
mutation actualizarMC($fkCliente: Float!){
  actualizarMC(fkCliente: $fkCliente)
}
`;

export const ADD_RECARGA_LOTERIA = gql`
mutation addRecargaLoteria($fkCliente: Int!, $fkRecaudacion: Int!, $cuentaDestino: String!, $nombre: String!, $email: String!, $origen: String!, $reserva: String!, $sorteo: String!, $boletos: String!, $token: String!, $ip: String!){
  addRecargaLoteria(fk_cliente: $fkCliente, fk_recaudacion: $fkRecaudacion, cuenta_destino: $cuentaDestino, nombre: $nombre, email: $email, origen: $origen, reserva: $reserva, sorteo: $sorteo, boletos: $boletos, token: $token, ip: $ip) {
    id
    cuenta_destino
    cantidad
    total_venta
    fecha
    hora
    respuesta
    estado
    puntos
    cliente {
      saldo
      saldo_anterior
    }
    recaudacion {
      name
      empresa
      proveedors {
        comision
      }
    }
  }
}
`;